<template>
  <div class="realExamination">
    <div class="langscap" :style="'width:' +  screenHeight + 'px; height:' + screenHeight + 'px;'">
      <div class="warp" :style="'width:' + screenHeight  + 'px; height:' + screenWidth + 'px;'">
        <div class="warpCenter">
          <div class="realTop">
            <div class="left">
              <p class="title">{{user.nickname}}</p>
              <p class="stage">第01考台</p>
              <fieldset class="minationDetail">
                <legend class="lege">考生信息</legend>
                <img class="img" :src="user.head" alt="">
                <p class="jiesao jisTop">姓名: {{ user.nickname }}</p>
                <p class="jiesao">性别: {{ user.sex }}</p>
                <p class="jiesao">类型: 小车</p>
                <p class="jiesao">科目: {{ this.car == 1 ? '科目一' : '科目四' }}</p>
              </fieldset>
              <fieldset class="lowTime">
                <legend class="lege">剩余时间</legend>
                <van-count-down
                  ref="countDown"
                  :time="time"
                  format="mm : ss "
                  @change="changetime"
                />
              </fieldset>
            </div>
            <div class="center">
              <fieldset class="itemContent">
                <legend class="lege">考试题目</legend>
                <p class="items">{{ indexcount + 1 }}.{{ countForm.contentStr }}
                  <img
                  v-if="vocieshow"
                  class="voice"
                  src="../assets/image/voicebefore.png"
                  alt=""
                  @click="play(1)"
                />
                <img
                  v-else
                  class="voice"
                  src="../assets/image/voiceafter.gif"
                  alt=""
                  @click="play(2)"
                />
                </p>
                <audio ref="audio" :src="countForm.audios || countForm.audios[0] || countForm.audios[0].url" style="opacity: 0" @ended="myFunction"></audio>
                <div class="choose">
                  <p class="text" v-for="item in countForm.optionCreates" :key="item.optionNoCn">{{item.optionNoCn}}.{{item.content}}</p>
                </div>
              </fieldset>
              <div class="itemBottom">
                <p class="yourchoose">您选择的答案:</p>
                <div class="choose">
                  <p class="text">选项:</p>
                  <div v-if="countForm.type !== 3">
                    <div 
                      v-for="(item, index) in countForm.optionCreates" 
                      :key="index" 
                      :class="selectChose === item.content ? 'selectBox' : 'box'" 
                      @click="select(item, index)"
                      >
                        {{ countForm.type === 2 ? item.optionNoCn : item.content}}
                    </div>
                  </div>
                  
                  <div v-if="countForm.type === 3">
                    <div 
                      v-for="(jetm, index) in countForm.optionCreates" 
                      :key="jetm.content" 
                      :class="{'selectBox' : isSelected(jetm.optionNoCn), 'box': true}" 
                      @click="selectMore(jetm, index)"
                      >
                        {{ jetm.optionNoCn }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tableBox">
              <div class="tabledBoxTop">
                <div class="box bgbox" v-for="item in 11" :key="item">
                  <p v-if="item === 1">题目</p>
                  <p v-else-if="item < 12">{{item - 1}}列</p>
                </div>
              </div>
              <div class="tableBottom">
                <div class="tableMainleft">
                  <div class="box bgbox" v-for="item in 10" :key="item">
                    <p>{{ item }}行</p>
                  </div>
                </div>
                <div class="tableright">
                  <div v-for="(item, index) in countList" :key="item.id"  @click="itemClick(index)" :class="index === indexcount ? 'box box1' : 'box'">
                    <p v-if="car == 1" style="color: #000; font-size: 12px;">{{ item.optionNoCn }}</p>
                    <p v-if="car == 2 && item.result.length == 0" style="color: #000; font-size: 12px;">{{ item.optionNoCn }}</p>
                    <p class="boxText" v-else-if="car == 2 && item.result.length > 0" :style="`color: #000; ${item.result.length >= 2 ? 'font-size: 8px' : 'font-size: 12px'}; ${item.result.length >= 3 ? 'line-height: 1' : 'line-height: 17px'} `">{{ item.result.join('') }}</p>
                    <!-- <p v-if="item.isSelect && item.selectTrue" style="color: #000; font-size: 12px;">{{ item.select }}</p>
                    <p v-if="item.isSelect && !item.selectTrue" style="color: #000; font-size: 12px;">{{ item.select }}</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="realBom">
            <div class="topTip">
              <div class="left">
                <p class="hint">操作提示：判断题</p>
                <p class="smallhint">本题为判断题，请判断对错！</p>
              </div>
              <div class="rightBtn">
                <div class="box" @click="last">上一题</div>
                <div class="box" @click="next">下一题</div>
                <div class="box" @click="submit">交卷</div>
              </div>
            </div>
            <div class="btmimg">
              <div class="topicImg" v-if="countForm.imgList">
                <van-image
                  fit="contain"
                  v-for="(item, index) in countForm.imgList"
                  :key="index"
                  :src="item.url || item"
                  @click="HandleclickImg(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="diagModal" v-if="modalShow" :style="'width:' + screenHeight  + 'px; height:' + screenWidth + 'px;'">
        <div class="madalWarp">
          <div class="title">考试确认窗口</div>
          <div class="main">
            <p class="mesage">操作提示:</p>
            <div class="text">你当前考试答对{{ rightList.length }}题，答错{{ errorList.length }}题，未答{{ car == 1 ? 100 - (rightList.length + errorList.length) : 50 - (rightList.length + errorList.length) }}题</div>
            <div class="text">1.点击[确认交卷]，将提交考试成绩，考试结束!</div>
            <div class="text">2.点击[继续考试]，将关闭本窗口，继续考试!</div>
          </div>
          <div class="buttonBom">
            <div class="btn" @click="() => { submitShow = true }">确认交卷</div>
            <div class="btn" @click="() => { modalShow = false; this.$refs.countDown.start() }">继续考试</div>
          </div>
        </div>
      </div>
      <div class="diagModal" v-if="submitShow" :style="'width:' + screenHeight  + 'px; height:' + screenWidth + 'px;'">
        <div class="madalWarp">
          <div class="title">考试确认窗口</div>
          <div class="mainsubmit">
            <div class="name">[多杰泰学员] {{ user.nickname }}考生</div>
            <div v-if="car == 1">
              <div v-if="rightList.length < 90" class="text text-center">您本次考试得分{{ rightList.length }}分，不合格!祝你下次考试成功。</div>
              <div v-else class="text">您本次考试得分{{ rightList.length }}分，合格!祝贺你。</div>
            </div>
            <div v-else>
              <div v-if="rightList.length * 2 < 90" class="text text-center">您本次考试得分{{ rightList.length * 2 }}分，不合格!祝你下次考试成功。</div>
              <div v-else class="text">您本次考试得分{{ rightList.length * 2 }}分，合格!祝贺你。</div>
            </div>
          </div>
          <div class="buttonBom tp-10">
            <div class="backOver" @click="finish">关闭</div>
            <div class="btn" @click="goerror">回顾试卷</div>
          </div>
          <p class="tipToo">
            页面将在
            <van-count-down 
              class="timeoUt" 
              style="display: inline-block;" 
              ref="TIMEOUT" 
              :time="timeout" 
              @finish="finish"
            >
            <template #default="timeData">
              {{ timeData.seconds }}
            </template>
            </van-count-down>
            秒后自动关闭，返回考试主界面
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addmastick } from '@/api/secondSelect'
import { ImagePreview } from "vant";
import { getUser } from '@/api'
export default {
  data() {
    return {
      screenWidth: '',
      screenHeight: '',
      user: {},
      car: 1,
      time: 60 * 60 * 1000,
      timeout: 10 * 1000,
      // 剩余时间
      altTime: {},
      minutes: '',
      seconds: '',
      isSelect: false,
      // 正确索引
      trueIndex: '',
      // 当前单选或者多选选中下标
      cutindex: '',
      // 当前索引
      indexcount: 0,
      // 播放音频图标是否显示
      vocieshow: true,
      // 题目数组
      countList: [],
      // 做错题数组
      errorList: [],
      // 做对题数组
      rightList: [],
      // 当前题目对象
      countForm: {},
      // 总题数
      allnum: 100,
      // 已做题数
      numsbefor: 2,
      // 正确题数
      sucesnum: 1,
      // 错误题数
      errnum: 1,
      // 是否vip
      vip: false,
      // 选择对错
      selectChose: '',
      // 选择答案
      chooseaswer: '',
      // 选项
      optionNoCn: '',
      // 多选答案
      resultList: [],
      // 多选答案记录
      optionNocnResult: [],
      submitShow: false,
      modalShow: false,
      user: {}
    }
  },
  created() {
    this.screenHeight = window.innerHeight
    this.screenWidth = window.innerWidth
    this.user = JSON.parse(localStorage.getItem('login')) ? JSON.parse(localStorage.getItem('login')) : {head: '', nickname: ''}
    this.car = this.$route.query.id
    if (this.$route.query.id == 1 || this.$route.query.id == 2) {
      this.vip = JSON.parse(localStorage.getItem('vip'))
      this.countList = JSON.parse(localStorage.getItem('tixing'))
      this.indexcount = 0
    } else {
      this.vip = JSON.parse(localStorage.getItem('vip'))
      this.countList = JSON.parse(localStorage.getItem('errortixing'))
      this.errorList = JSON.parse(localStorage.getItem('errortixing'))
      this.errorTrue = JSON.parse(localStorage.getItem('errortixing')).length > 0 ? false : true
      this.indexcount = 0
    }
    this.countForm = this.countList[this.indexcount]
    this.getUserIfo()
  },
  mounted() {
    this.$refs.countDown.start();
  },
  methods: {
    getUserIfo() {
      getUser().then(res => {
        this.user = res.data
      })
    },
    itemClick(item) {
      console.log(this.countList)
      this.indexcount = item
      this.countForm = this.countList[item]
      if (this.countList[this.indexcount].isSelect) {
        if (this.countForm.type === 3) {
          this.resultList = this.countList[this.indexcount].result
          this.optionNocnResult = this.countList[this.indexcount].result
        } else {
          this.selectChose = this.countList[this.indexcount].selectChose
          this.chooseaswer = this.countList[this.indexcount].chooseaswer
          this.optionNoCn = this.countList[this.indexcount].optionNoCn
        }
      } else {
        this.resultList = []
        this.selectChose = ''
        this.chooseaswer = ''
        this.optionNoCn = ''
        this.optionNocnResult = []
      }
    },
    // 获取当前时间
    changetime(e) {
      this.minutes = e.minutes;
      this.seconds = e.seconds;
    },
    HandleclickImg(e) {
      ImagePreview({
        images: [`${e}` || `${e.url}`],
        onClose() {

        }
      })
    },
    // 下一题
    next() {
      this.vocieshow = true
      // 显示上一个题目的逻辑
      if (this.countForm.type !== 3) {
        // 判断是否有选择答案 有答案就给赋值
        if (this.chooseaswer) {
          this.countList[this.indexcount].isSelect = true
          this.countList[this.indexcount].selectChose = this.selectChose
          this.countList[this.indexcount].chooseaswer = this.chooseaswer
          this.countList[this.indexcount].optionNoCn = this.countList[this.indexcount].type === 1 ? this.selectChose : this.optionNoCn
          // 判断题正确错误判断
          if (this.countForm.answer === this.chooseaswer && this.countForm.type == 1) {
            if (this.rightList.indexOf(this.countForm) < 0) {
              this.rightList.push(this.countForm)
            }
            this.countList[this.indexcount].selectTrue = true
            this.countList[this.indexcount].select = '√'
          } else if (this.countForm.answer !== this.chooseaswer && this.countForm.type == 1) {
            if (this.errorList.indexOf(this.countForm) < 0) {
              this.errorList.push(this.countForm)
            }
            addmastick({ids: [this.countForm.id]}).then(res => {})
            this.countList[this.indexcount].selectTrue = false
            this.countList[this.indexcount].select = '×'
          }
          // 选择题正确错误判断
          if (this.countForm.three === this.chooseaswer && this.countForm.type == 2) {
            if (this.rightList.indexOf(this.countForm) < 0) {
              this.rightList.push(this.countForm)
            }
            this.countList[this.indexcount].selectTrue = true
            this.countList[this.indexcount].select = '√'
          } else if (this.countForm.three !== this.chooseaswer && this.countForm.type == 2) {
            if (this.errorList.indexOf(this.countForm) < 0) {
              this.errorList.push(this.countForm)
            }
            addmastick({ids: [this.countForm.id]}).then(res => {})
            this.countList[this.indexcount].selectTrue = false
            this.countList[this.indexcount].select = '×'
          }
        }
      } else {
        if (this.resultList.length > 0) {
          this.countList[this.indexcount].isSelect = true
          this.countList[this.indexcount].result = this.resultList.sort()
          const arr = this.countForm.optionCreates
          const trueList = []
          arr.forEach((item, index) => {
            if (item.isTrue) {
              trueList.push(item.optionNoCn)
            }
          })
          if (JSON.stringify(trueList) === JSON.stringify(this.resultList)) {
            if (this.rightList.indexOf(this.countForm) < 0) {
              this.rightList.push(this.countForm)
            }
            this.countList[this.indexcount].selectTrue = true
            this.countList[this.indexcount].select = '√'
          } else {
            if (this.errorList.indexOf(this.countForm) < 0) {
              this.errorList.push(this.countForm)
            }
            addmastick({ids: [this.countForm.id]}).then(res => {})
            this.countList[this.indexcount].selectTrue = false
            this.countList[this.indexcount].select = '×'
          }
        }
      }
      // 当前选择项的操作
      this.indexcount++;
      if (this.indexcount > 49 && this.car == 2 || this.car == 1 && this.indexcount > 99) {
        this.indexcount = this.car == 1 ? 99 : 49
      }
      this.countForm = this.countList[this.indexcount];
      if (this.countForm.type === 3) {
        if (this.countList[this.indexcount].isSelect) {
          this.resultList = this.countList[this.indexcount].result
          this.optionNocnResult = this.countList[this.indexcount].result
        } else {
          this.resultList = []
          this.optionNocnResult = []
        }
      } else {
        if (this.countList[this.indexcount].isSelect) {
          this.selectChose = this.countList[this.indexcount].selectChose
          this.chooseaswer = this.countList[this.indexcount].chooseaswer
          this.optionNoCn = this.countList[this.indexcount].optionNoCn
        } else {
          this.selectChose = ''
          this.chooseaswer = ''
          this.optionNoCn = ''
        }
      }
    },
    // 播放音乐
    play(e) {
      this.vocieshow = !this.vocieshow
      if (e === 1) {
        this.$refs.audio.play()
      } else {
        this.$refs.audio.pause()
      }
    },
    myFunction() {
      this.vocieshow = !this.vocieshow
    },
    // 交卷
    submit() {
      this.modalShow = true
      this.$refs.countDown.pause()
    },
    // 上一题
    last() {
      if (this.indexcount == 0) {
        return false;
      } else {
        this.indexcount--;
        this.countForm = this.countList[this.indexcount];
        if (this.countForm.type !== 3) {
          if (this.countList[this.indexcount].isSelect) {
            this.selectChose = this.countList[this.indexcount].selectChose
            this.chooseaswer = this.countList[this.indexcount].chooseaswer
            this.optionNoCn = this.countList[this.indexcount].optionNoCn
          } else {
            this.selectChose = ''
            this.chooseaswer = ''
            this.optionNoCn = ''
          }
        } else {
          if (this.countList[this.indexcount].isSelect) {
            this.resultList = this.countList[this.indexcount].result
            this.optionNocnResult = this.countList[this.indexcount].result
          } else {
            this.resultList = []
            this.optionNocnResult = []
          }
        }
      } 
      if (this.countList[this.indexcount].type === 3) {
        this.result = this.countList[this.indexcount].result
        this.optionNocnResult = this.countList[this.indexcount].result
      }
    },
    // 选择答案
    select(e, index) {
      console.log(e)
      if(this.countList[this.indexcount].isSelect) { // 是否已经选择过 
        return false
      } else {
        this.selectChose = e.content
        this.chooseaswer = e.optionNo
        this.optionNoCn = e.optionNoCn
      }
    },
    // 多选题确认按钮
    selectMore(item) {
      if (this.countList[this.indexcount].isSelect) {
        return false
      } else {const index = this.resultList.indexOf(item.optionNoCn)
        if (index === -1) {
          this.resultList.push(item.optionNoCn)
        } else {
          this.resultList.splice(index, 1);
        }
      }
    },
    isSelected(e) {
      return this.resultList.includes(e)
    },
    // 倒计时结束
    finish() {
      this.$router.push('/')
    },
    goerror() {
      this.$router.push({
        path: 'mastickandCollect',
        query: {
        id: this.car
      }}
      )
    }
  }
}
</script>

<style scoped lang="scss">
.realExamination{
  width: 100%;
  height: 100%;
  background: #F1F4ED;
  overflow: hidden;
  .langscap{
    background: #000000;
    border-radius: 0px 0px 0px 0px;
    transform: rotate(90deg);
    transform-origin: bottom top;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: flex-end;
    .warp{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #F1F4ED;
      .warpCenter{
        width: 655px;
        height: 100%;
        overflow-y: scroll;
        background: #F1F4ED;
        .realTop{
          width: 100%;
          height: 214px;
          display: flex;
          justify-content: center;
          .left{
            width: 65px;
            padding-top: 8px;
            margin-left: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title{
              font-family: PingFang SC, PingFang SC;
              font-weight: bold;
              font-size: 8px;
              color: #2184D2;
            }
            .stage{
              font-family: PingFang SC, PingFang SC;
              font-weight: bold;
              font-size: 9px;
              color: #2B2B2B;
            }
            .minationDetail{
              width: 64px;
              height: 130px;
              margin-top: 8px;
              border: 1px solid rgb(112, 112, 112, .56);
              display: flex;
              flex-direction: column;
              align-items: center;
              .lege{
                text-align: center;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 12px;
                color: #2184D2;
              }
              .img{
                margin-top: 11px;
                width: 44px;
                height: 44px;
              }
              .jiesao{
                width: 100%;
                text-align: left;
                padding-left: 4px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 8px;
                color: #555555;
              }
              .jisTop{
                margin-top: 11px;
              }
            }
            .lowTime{
              width: 65px;
              height: 24px;
              margin-top: 6px;
              border: 1px solid rgb(112, 112, 112, .56);
              .lege{
                text-align: center;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 8px;
                color: #2184D2;
              }
              ::v-deep .van-count-down{
                text-align: center;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 10px;
                color: #2B2B2B;
                line-height: 12px;
              }
            }
          }
          .center{
            width: 347px;
            padding-top: 8px;
            margin-left: 4px;
            .itemContent{
              width: 347px;
              height: 164px;
              border: 1px solid rgba(112, 112, 112, .56);
              overflow-y: scroll;
              .lege{
                margin-left: 10px;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 8px;
                color: #2184D2;
              }
              .voice {
                width: 20px;
                height: 20px;
                margin-bottom: -3px;
                margin-left: 4px;
                display: inline-block;
                transform: translateY(6px);
              }
              .items{
                width: 327px;
                margin: 10px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 800;
                font-size: 12px;
                color: #333333;
              }
              .choose{
                margin-top: 8px;
                margin-left: 10px;
                .text{
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 800;
                  font-size: 11px;
                  color: #333333;
                }
              }
            }
            .itemBottom{
              height: 37px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .yourchoose{
                margin-left: 8px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 800;
                font-size: 10px;
                color: #2184D2;
              }
              .choose{
                display: flex;
                align-items: center;
                div{
                  display: flex;
                }
                .text{
                  font-family: PingFang SC, PingFang SC;
                  font-weight: bold;
                  font-size: 10px;
                  color: #555555;
                  margin-right: 12px;
                }
                .box{
                  width: 25px;
                  height: 25px;
                  margin-right: 8px;
                  background: #FFFFFF;
                  border-radius: 0px 0px 0px 0px;
                  border: 1px solid #969798;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: bold;
                  font-family: Kailasa;
                  font-size: 16px;
                  line-height: 1;
                  color: #000000;
                }
                .selectBox{
                  width: 25px;
                  height: 25px;
                  margin-right: 8px;
                  background: #54ACF1;
                  border: 1px solid #54ACF1;
                  border-radius: 0px 0px 0px 0px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: bold;
                  font-family: Kailasa;
                  font-size: 16px;
                  color: #fff;
                }
              }
            }
          }
          .tableBox{
            width: 200px;
            height: 200px;
            margin-left: 4px;
            margin-top: 8px;
            background: #FFFFFF;
            border-right: 1px solid #CBD2DB;
            .tabledBoxTop{
              display: flex;
            }
            .tableBottom{
              display: flex;
              .tableright{
                width: 182px;
                height: 182px;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
              }
            }
            .box{
              width: 17px;
              height: 17px;
              white-space: nowrap;
              border: 1px solid #CBD2DB;
              border-top: 0px;
              border-left: 0px;
              p{
                text-align: center;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 8px;
                color: #000000;
                line-height: 19px;
              }
              .boxText{
                width: 17px;
                height: 17px;
                white-space: pre-wrap;
                word-break: break-all;
                text-align: center;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 8px;
                color: #000000;
                line-height: 1;
              }
            }
            .bgbox{
              background: #54ACF1;
            }
            .box1{
              width: 17px;
              height: 17px;
              background: #54ACF1;
              border-radius: 0px 0px 0px 0px;
              p{
                text-align: center;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 12px;
                color: #fff;
                line-height: 17px;
              }
            }
          }
        }
        .realBom{
          width: calc(100% - 80px);
          margin-left: 40px;
          height: 142px;
          border: 1px solid #707070;
          border-top: 3px solid #2184D2;
          .topTip{
            width: 100%;
            height: 35px;
            border-bottom: 3px solid #2184D2;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left{
              .hint{
                font-family: PingFang SC, PingFang SC;
                font-weight: 800;
                font-size: 10px;
                color: #FF4A40;
                margin-left: 8px;
              }
              .smallhint{
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 8px;
                color: #555555;
                margin-left: 8px;
              }
            }
            .rightBtn{
              display: flex;
              .box{
                width: 63px;
                height: 22px;
                text-align: center;
                background: #FFFFFF;
                border-radius: 0px 0px 0px 0px;
                border: 1px solid #969798;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 12px;
                color: #000000;
                line-height: 22px;
                margin-right: 9px;
              }
            }
          }
          .btmimg{
            width: 100%;
            height: 104px;
            display: flex;
            align-items: center;
            justify-content: center;
            .topicImg{
              height: 104px;
              display: flex;
              justify-content: center;
              align-items: center;
              .van-image{
                height: 90px;
              }
            }
          }
        }
      }
    }
  }
  .diagModal{
    background: rgba(0, 0, 0, .33);
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .madalWarp{
      width: 383px;
      height: 236px;
      background: #1B77E2;
      border-radius: 6px 6px 6px 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title{
        width: 100%;
        height: 29px;
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 29px;
        letter-spacing: 2px;
      }
      .main{
        width: 357px;
        height: 142px;
        padding-left: 17px;
        background: #F5F2ED;
        .mesage{
          margin-top: 28px;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 12px;
          color: #333333;
          letter-spacing: 2px;
          margin-bottom: 10px;
        }
      }
      .mainsubmit{
        width: 374px;
        height: 109px;
        background: #F5F2ED;
        border-radius: 0px 0px 0px 0px;
      }
      .text{
        font-family: PingFang SC, PingFang SC;
        font-size: 12px;
        color: #333333;
        letter-spacing: 2px;
        margin-bottom: 6px;
      }
      .text-center{
        text-align: center;
      }
      .name{
        margin-top: 35px;
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 12px;
        color: #333333;
        letter-spacing: 2px;
      }
      .buttonBom{
        width: 202px;
        height: 31px;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        .btn{
          width: 88px;
          height: 31px;
          background: #FFFFFF;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #969798;
          text-align: center;
          line-height: 31px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 12px;
          color: #000000;
          letter-spacing: 2px;
        }
        .backOver{
          width: 88px;
          height: 31px;
          background: #4982DE;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #FFFFFF;
          text-align: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 800;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 31px;
          letter-spacing: 2px;
        }
      }
      .tipToo{
        margin-top: 9px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 9px;
        color: #FFFFFF;
        letter-spacing: 2px;
        text-align: center;
        .timeoUt{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 9px;
          color: #FFFFFF;
        }
      }
      .tp-10{
        margin-top: 26px;
      }
    }
  }
}
.van-image-preview__image ::v-deep  img{
  -webkit-transform: rotate(90deg) !important;
  transform:rotate(90deg);
}
</style>
